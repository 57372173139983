import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {GuestPaymentFormObject} from '@app/definitions/types';
import { ApiService } from '@app/services/api.service';
import { LangService } from '@app/services/lang.service';
import {EventsService} from "@app/services/events.service";

@Component({
  selector: 'app-guest-payment-form',
  templateUrl: './guest-payment-form.component.html',
  styleUrl: './guest-payment-form.component.css'
})
export class GuestPaymentFormComponent implements OnInit {
  guestPaymentFormObject: GuestPaymentFormObject = new GuestPaymentFormObject();	//object containing the data to be displayed and the iframe link
  isSaving: boolean = false;
  isBackFromFirstApiCall:boolean=false;
  isFetching2ndApi:boolean=false;
  isFetching2ndApi2:boolean=false;
  search:any=null;
  @Input() code:string=null;

  constructor(private apiService: ApiService, public lang: LangService, public sanitizer: DomSanitizer,public eventsService: EventsService) { }

  getSendObj(){
    const obj:any={vars:this.search}
    if(this.code){
      obj.kdfptoegf345gt46tg=this.code;
    }
    return obj;
  }

  async ngOnInit() {
    this.isFetching2ndApi=false;
    this.isFetching2ndApi2=false;
    this.isBackFromFirstApiCall=false;
    this.isSaving = false;
    if(window.location?.search?.length){
      this.search=window.location.search.substring(1).split("&").map(it=>it.split("="));
    }


    const res:any=await this.apiService.post("get_guest_payment_form_data",this.getSendObj());
    this.isBackFromFirstApiCall=true;
    this.guestPaymentFormObject = res;
  }
  async redirect(){
    this.isFetching2ndApi=true;
    const res:any=await this.apiService.post("get_guest_redirect_link",this.getSendObj());
    //const res:any=await this.apiService.post("get_guest_grow_redirect_link",this.getSendObj());
    console.log(res);
    this.isFetching2ndApi=false;
    if(res.link){
      (window.location.href as any)=res.link;
    }
  }

}
